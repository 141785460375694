<template>
  <a-row type="flex" justify="space-between">
    <a-col :span="4">
      <a-button
        v-if="type !== 'all'"
        type="primary"
        @click="
          $has(`${type}:add`).then(() => $router.push(`${$route.path}/add`))
        "
      >
        <template #icon><plus-outlined /></template>
        创建任务
      </a-button>
    </a-col>
    <a-col :span="8">
      <a-input-search
        placeholder="请输入名称"
        allow-clear
        @search="value => handleSearch({ campaignName: value || null })"
      />
    </a-col>
  </a-row>
  <a-tabs>
    <a-tab-pane key="1" tab="列表视图">
      <a-row type="flex">
        <a-col flex="250px">
          <iss-sidebar
            v-model:group-list="groupList"
            :type="type"
            @fnSearch="handleSearch"
          />
        </a-col>
        <a-col flex="1">
          <iss-list :type="type" :group-list="groupList" :search="search" />
        </a-col>
      </a-row>
    </a-tab-pane>
    <a-tab-pane key="2" tab="日历视图">
      <a-row type="flex">
        <a-col flex="250px">
          <iss-sidebar
            v-model:group-list="groupList"
            :type="type"
            @fnSearch="handleSearch"
          />
        </a-col>
        <a-col flex="1">
          <iss-calendar :type="type" :search="search" />
        </a-col>
      </a-row>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import { reactive, toRefs, watch } from 'vue';
import { useRoute } from 'vue-router';
import { Col, Row, Tabs } from 'ant-design-vue';
import { PlusOutlined } from '@ant-design/icons-vue';
import IssSidebar from './components/sidebar';
import IssList from './components/list';
import IssCalendar from './components/calendar';

export default {
  components: {
    ACol: Col,
    ARow: Row,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    PlusOutlined,
    IssSidebar,
    IssList,
    IssCalendar,
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      type: route.path.split('/')[3],
      search: {},
      groupList: [],
    });
    watch(
      () => route.path,
      path => {
        state.type = path.split('/')[3];
      }
    );

    return {
      ...toRefs(state),
      handleSearch: value => Object.assign(state.search, value),
    };
  },
};
</script>
