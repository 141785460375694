<template>
  <div class="list-wrapper">
    <a-row type="flex" align="middle" class="list-title">
      <a-col :flex="1">
        <a-checkbox
          v-model:checked="checkAll"
          :indeterminate="indeterminate"
          @change="handleChangeByCheckAll"
        >
          全选
        </a-checkbox>
        <a-dropdown>
          <span class="move">
            移动到
            <down-outlined />
          </span>
          <template #overlay>
            <a-menu @click="handleClickByMenu">
              <a-menu-item v-for="item in groupList" :key="item.id">
                {{ item.campaignGroupName }}
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </a-col>
      <a-range-picker allow-clear @change="handleChangeByDate">
        <template #suffixIcon><calendar-outlined /></template>
      </a-range-picker>
    </a-row>
    <a-checkbox-group
      v-model:value="selectedKeys"
      @change="handleChange"
      style="width: 100%"
    >
      <a-list
        item-layout="horizontal"
        :loading="loading"
        :data-source="dataSource"
        :pagination="pagination"
      >
        <template #renderItem="{ item }">
          <a-list-item>
            <div class="mr-13">
              <a-checkbox :value="item.id" />
            </div>
            <a-list-item-meta>
              <template #avatar>
                <img
                  class="banner"
                  :src="item.banner"
                  @click="handleClick(item)"
                />
              </template>
              <template #title>
                <div class="mt-10">
                  <a-tag v-if="getStatus(item) === 0" color="processing">
                    未开始
                  </a-tag>
                  <a-tag v-else-if="getStatus(item) === 1" color="default">
                    已结束
                  </a-tag>
                  <a-tag v-else color="success">进行中</a-tag>
                  <span class="title" @click="handleClick(item)">
                    {{ item.campaignName }}
                  </span>
                </div>
              </template>
              <template #description>
                <div class="address">
                  {{ item.country }}
                  {{ item.province ? ` • ${item.province}` : '' }}
                  {{ item.city ? ` • ${item.city}` : '' }}
                </div>
                <div>
                  {{ dateFormat(item.startTime) }} ~
                  {{ dateFormat(item.endTime) }}
                </div>
              </template>
            </a-list-item-meta>
            <template #actions>
              <operation :options="options" :record="item" />
            </template>
            <a-space size="large">
              <div class="statistic-item">
                <div class="item-count">{{ item.attendeeCount }}</div>
                <div class="item-title">报名人数</div>
              </div>
              <div class="statistic-item" v-if="item.loginCount">
                <div class="item-count">{{ item.loginCount }}</div>
                <div class="item-title">登录数</div>
              </div>
              <div class="statistic-item" v-else>
                <div class="item-count">{{ item.checkinCount }}</div>
                <div class="item-title">签到数</div>
              </div>
            </a-space>
          </a-list-item>
        </template>
      </a-list>
    </a-checkbox-group>
  </div>
</template>

<script>
import { computed, getCurrentInstance, reactive, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  List,
  Menu,
  Row,
  Space,
  Tag,
} from 'ant-design-vue';
import { DownOutlined, CalendarOutlined } from '@ant-design/icons-vue';
import Operation from '@/components/operation';
import { dateFormat } from '@/utils';
import activityApi from '@/api/activity.js';

export default {
  components: {
    AList: List,
    AListItem: List.Item,
    AListItemMeta: List.Item.Meta,
    ATag: Tag,
    ASpace: Space,
    ACheckbox: Checkbox,
    ACheckboxGroup: Checkbox.Group,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ARangePicker: DatePicker.RangePicker,
    ARow: Row,
    ACol: Col,
    DownOutlined,
    CalendarOutlined,
    Operation,
  },
  props: {
    type: String,
    search: Object,
    groupList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const typeList = {
      host: '101',
      exhibition: '102',
      live: '103',
      thematic: '104',
      socialmedia: '105',
    };
    const route = useRoute();
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      loading: false,
      dataSource: [],
      searchDate: {},
      checkAll: false,
      indeterminate: false,
      selectedKeys: [],
      current: 1,
      size: 10,
      total: 0,
    });
    const pagination = computed(() => {
      return {
        current: state.current,
        pageSize: state.size,
        total: state.total,
        showLessItems: true,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `共 ${total} 条 当前显示 ${range[0]}-${range[1]} 条`,
        onChange: (current, pageSize) => initList(current, pageSize),
        onShowSizeChange: (current, pageSize) => initList(current, pageSize),
      };
    });
    const initList = (current, size) => {
      state.loading = true;
      state.current = current;
      state.size = size;
      activityApi
        .getList(route.path, {
          tempId: typeList[props.type],
          current,
          size,
          ...props.search,
          ...state.searchDate,
        })
        .then(({ records, total }) => {
          state.dataSource = records;
          state.total = parseInt(total);
          state.checkAll = false;
          state.indeterminate = false;
          state.selectedKeys = [];
        })
        .finally(() => {
          state.loading = false;
        });
    };
    const goTo = ({ id, eventId, campaignId }) =>
      router.push(`${route.path}/${id}/${eventId}/${campaignId}`);
    watch([props.search, () => props.type], () => initList(1, state.size));
    initList(state.current, state.size);
    return {
      options: [
        {
          type: 'detail',
          label: '详情',
          icon: 'EyeTwoTone',
          permission: () => `${props.type}:detail`,
          fnClick: goTo,
        },
        {
          type: 'copy',
          label: '复制',
          icon: 'CopyTwoTone',
          permission: () => `${props.type}:copy`,
          fnClick: ({ id }) => {
            activityApi.getItemById(`${props.type}:copy`, id).then(data => {
              data.campaignName += ' copy';
              data.tempApp = Object.values(data.tempApp)
                .map(item => item.map(i => ({ ...i, isConfig: 'true' })))
                .flat();
              delete data.id;
              activityApi.add(`${props.type}:copy`, data).then(() => {
                proxy.$message.success('操作成功');
                initList(1, state.size);
              });
            });
          },
        },
        {
          type: 'delete',
          permission: () => `${props.type}:delete`,
          fnClick: ({ id }) => {
            activityApi
              .delete(`${props.type}:delete`, { ids: [id] })
              .then(() => {
                proxy.$message.success('操作成功');
                initList(1, state.size);
              });
          },
        },
      ],
      pagination,
      ...toRefs(state),
      dateFormat,
      handleClick: item => {
        proxy.$has(`${props.type}:detail`).then(() => goTo(item));
      },
      handleClickByMenu: ({ key }) => {
        if (state.selectedKeys.length) {
          Promise.all(
            state.selectedKeys.map(item =>
              activityApi.update(`${props.type}:group`, {
                ...state.dataSource.find(i => i.id === item),
                campaignGroupId: key,
              })
            )
          ).then(() => {
            proxy.$message.success('操作成功');
            initList(state.current, state.size);
          });
        } else {
          proxy.$message.info('请至少选择一条数据');
        }
      },
      handleChangeByDate: (dates, dateStrings) => {
        state.searchDate = dateStrings[0]
          ? {
              startDate: `${dateStrings[0]} 00:00:00`,
              endDate: `${dateStrings[1]} 23:59:59`,
            }
          : {};
        initList(1, state.size);
      },
      handleChange: selectedKeys => {
        if (selectedKeys.length) {
          if (selectedKeys.length === state.dataSource.length) {
            state.checkAll = true;
            state.indeterminate = false;
          } else {
            state.checkAll = false;
            state.indeterminate = true;
          }
        } else {
          state.checkAll = false;
          state.indeterminate = false;
        }
      },
      handleChangeByCheckAll: ({ target }) => {
        state.indeterminate = false;
        state.selectedKeys = target.checked
          ? state.dataSource.map(item => item.id)
          : [];
      },
      getStatus: item => {
        const now = new Date().getTime();
        const startTime = new Date(item.startTime).getTime();
        const endTime = new Date(item.endTime).getTime();
        if (now < startTime) return 0;
        if (now > endTime) return 1;
      },
    };
  },
};
</script>

<style lang="less" scoped>
.list-wrapper {
  padding: 20px;
  border: 1px solid @border-color-base;
  max-height: calc(100vh - 201px);
  overflow-y: auto;
  .list-title {
    padding: 12px 0;
    border-bottom: 1px solid #f0f0f0;
  }
  .move {
    cursor: pointer;
  }
  .banner {
    height: 96px;
    width: 148px;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
  .title {
    vertical-align: middle;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      color: @primary-color;
    }
  }
  .address {
    margin-top: 15px;
    min-height: 22px;
  }
}
.statistic-item {
  .item-title {
    margin-top: 5px;
    color: @text-color-secondary;
  }
  .item-count {
    font-size: 32px;
    color: @primary-color;
  }
}
</style>
